import React, { useEffect } from "react";
import { GrayColor } from "../../constants/constant-ui";
import Text from "../data-display/text";
import moment from "moment";
import { Calendar, Globe, MapPin, Clock } from "react-feather";
import { FaRegHandPointUp, FaRegComments } from "react-icons/fa";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";
import SEO from "../../components/seo/seo";
import { useMatch } from "@reach/router";
import { UserData } from "../../models/user-data";
import Avatar from "../avatar/avatar";
import StarRating from "../reviews-ratings/star-rating";

const useStyles = makeStyles(() => ({
  taskCard: {
    display: "flex",
    flexDirection: "column",
    background: "white",
    position: "relative",
    borderRadius: "6px",
    cursor: "pointer",
    boxShadow: "0 1px 2px rgb(0,0,0,0.1)",
    transition: "0.3s",
    border: "1.5px solid rgb(245,245,245)",
    "&:hover": {
      boxShadow: "0 10px 20px -14px rgba(50,50,50,0.3)",
    },
  },
  selected: {
    boxShadow: "0 10px 20px -14px rgba(50,50,50,0.3)",
    border: "1.5px solid rgb(220,220,220)",
  },
}));

const TaskerCard = ({ user, index }: { user: UserData; index: any }) => {
  ///   const [hideStatusIndicator, setHide] = React.useState(hideStatus ? true : false);

  //   useEffect(() => {
  //     setOpen(task.id === selected);
  //   }, [selected]);

  const classes = useStyles();

  return (
    <>
      {/* <SEO title={document.title} description={''} pathname={`/tasks/${task.id}`} /> */}
      <div
        className={classNames({
          [classes.taskCard]: true,
        })}
      >
        <div
          style={{
            display: "flex",
            padding: "14px 16px 18px",
            // paddingBottom: "0px",
          }}
        >
          <div
            style={{ marginTop: "8px", marginRight: "20px", marginLeft: "8px" }}
          >
            {/* <Avatar
              userId={
                user[index]?.uid == user?.uid
                  ? user[index]?.slug_title
                  : user?.slug_title
              }
              profilePicture={user?.profilePicture}
              size={30}
            />{" "} */}
                                                <Avatar userId={user?.slug_title} profilePicture={user?.profilePicture ?? ""} name={user?.name ?? ""} size={24} />

          </div>
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <div style={{ height: "4px" }} />
            <Text variant={"body2"} style={{ lineHeight: 1 }} bold>
              {user?.name}
            </Text>
            <div style={{ height: "8px" }} />
            <Text variant={"subtitle1"} style={{ lineHeight: 1 }} bold>
              {user.service_area ?user.service_area : '--' }
            </Text>
            <div style={{ height: "4px" }} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <StarRating
                totalAverage={user.workerReviewIndex?.totalAverage}
                totalCount={user.workerReviewIndex?.totalCount}
              />
              <div style={{ width: "14px" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskerCard;
