import {Map, GoogleApiWrapper, Marker, InfoWindow} from 'google-maps-react';
import React from 'react';
import {GoogleMaps} from '../../utils/constant.urls';
import  './tasksMap.scss';
import {navigate} from 'gatsby';
import InfoWindowEx from './infoWindowEx';
import TaskCard from '../../components/task-components/task-card';
import CircularProgressBar from '../../components/progress-bars/circular-progress-bar';
import {getCategoryPinImage} from './category-pin';
import {FaSearchLocation} from "react-icons/fa";

const Fade = require('react-reveal/Fade');

class MapView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showingInfoWindow: false,
      activeMarker: null,
      selectedTask: null,
      currentLocation:{lat: 37.774929, lng: -122.419416}
    };
  }

  onMarkerClick = (props, marker, e) => {
    this.setState({
      ...this.state,
      selectedTask: props.task,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  showMore = () => {
    navigate(`/tasks${this.state.selectedTask.slug_title}`);
  };

  componentDidMount() {
    this.goToMyLocation()
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentLocation !== this.state.currentLocation) {
      this.goToMyLocation()
    }
  }

   goToMyLocation=()=>{
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const coords = pos.coords;
        this.setState({
          currentLocation: {
            lat: coords.latitude,
            lng: coords.longitude
          }
        })
      },function(e) {
        console.log(e)
      },{enableHighAccuracy:true,timeout:5000})
    }
  }

  render() {
    const containerStyle = {
      width: '100%',
      height: '100%',
      border: '1px solid #707070',
      position: 'relative',

    };

    const style = {
      padding: '24px 28px',
      background: 'white',
      borderRadius: '8px',
      marginTop: '64px',
      minHeight: 'calc(100vh - 116px)',
      boxSizing: 'border-box',
      boxShadow: '0 1px 2px rgb(0,0,0,0.1)',
      maxWidth: '100%',
    };

    return (
        <div>
          <Fade bottom distance={'20px'} duration={400} mountOnEnter unmountOnExit>

            <Map
                containerStyle={containerStyle}
                style={style}
                google={this.props.google}
                zoom={8}
                streetViewControl={false}
                mapTypeControl={false}
                fullscreenControl={false}
                initialCenter= {this.state.currentLocation}
                center={this.state.currentLocation}
                maxZoom={16}
                minZoom={3}
                centerAroundCurrentLocation={false}


            >
              <button id="btn-location" onClick={this.goToMyLocation}><FaSearchLocation size={20}/></button>
              {this.props.tasks &&
              this.props.tasks.map((task, index) => {
                return !task.isRemote ?(
                    <Marker icon={getCategoryPinImage(task.category).toString()} key={index} task={task}
                            onClick={this.onMarkerClick} /*onMouseover={this.onMarkerClick}*/
                            position={{
                              lat: task?._geoloc?.lat,
                              lng: task?._geoloc?.lng,
                            }}

                    />
                ):null;
              })}
              <Marker position={this.state.currentLocation}/>
              {this.state.selectedTask &&
              <InfoWindowEx
                  onClick={this.showMore}
                  marker={this.state.activeMarker}
                  visible={this.state.showingInfoWindow}
              >
                <div onClick={this.showMore}>
                  <TaskCard task={this.state.selectedTask}
                            selected={this.state.selectedTask.id}/>
                </div>
              </InfoWindowEx>
              }
            </Map>
          </Fade>
        </div>
    );
  }
}

const LoaderContainer = (props) => {
  return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <CircularProgressBar/>
      </div>
  );
};

export default GoogleApiWrapper({
  apiKey: GoogleMaps,
  LoadingContainer: LoaderContainer,
})(MapView);