import React, { useState } from "react"
import FormInput from "../../components/forms/form-input";
import Text from "../../components/data-display/text";
import MenuListItem from "../../components/menu-popper/menu-list-item";
import MenuWrapper from "../../components/menu-popper/menu-wrapper";
import { FiSearch } from "react-icons/fi";
import algoliasearch from "algoliasearch";
import { ALGOLIA_API_KEY, ALGOLIA_APP_ID } from "../../utils/constant.urls";

const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);
const taskersIndex = client.initIndex('users_query_suggestions');

const BrowseTaskersSearchBox = ({ onUpdate }: { onUpdate: (q: string) => void }) => {

    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState<string[]>([]);
    const [timer, setTimer] = useState<NodeJS.Timeout>();

    const getResults = (v: string) => {
        taskersIndex.search(v, {
            hitsPerPage: 5,
        }).then(({ hits }) => {
            let t: string[] = []
            if (hits.length) {
                hits.forEach((h) => {
                    t.push(h["query"])
                })
                setResults(t);
            } else {
                setResults([]);
            }
        });
    }

    const onChange = (values: Record<string, string | boolean>) => {
        if (values["searchQuery"]) {
            setQuery(values["searchQuery"].toString().trim());
            clearTimeout(timer);
            if (values["searchQuery"].toString().trim().length > 0) {
                setTimer(setTimeout(() => {
                    getResults(values["searchQuery"].toString().trim())
                }, 500))
            } else {
                setResults([]);
                setLoading(false);
            }
        } else {
            setQuery("");
            setLoading(false);
            setResults([]);
        }
    }

    return (
        <div style={{
            zIndex: 1300,
            width: "inherit",
            paddingRight: "18px",
            paddingLeft: "18px",
            marginTop:'20px',
            boxSizing: "border-box"
        }}>
            <div style={{ background: "rgb(247, 248, 251)", height: '8px', left: 0, right: 0, top: 0, position: "absolute" }} />
            <div style={{
                backdropFilter: "blur(10px)",
                background: "rgba(254, 254, 254, 0.8)",
                border: "1.5px solid rgb(232,232,232,0.9)",
                borderRadius: "8px"
            }}>
                <form style={{ maxWidth: "500px" }}

                    onSubmit={(event) => {
                        event.preventDefault();
                        onUpdate(query);
                        // if (formRef?.current)
                        // formRef.current.blur()
                    }}
                >
                    <MenuWrapper
                        live
                        disableToggle
                        items={
                            <div style={{ height: "auto" }}>
                                {
                                    query.trim().length === 0 ?
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80px" }}>
                                            <FiSearch style={{ color: "grey", marginRight: "12px" }} size={14} />
                                            <Text variant={"caption"} style={{ color: "grey", marginRight: "12px" }}>Start Typing...</Text>
                                        </div>
                                        :
                                        <div>
                                            <div>
                                                {(!loading && query.length > 0) &&
                                                    <MenuListItem active key={"live"} icon={<FiSearch size={14} style={{ flexShrink: 0 }} />} onClick={() => { onUpdate(query) }}>
                                                        <p style={{ margin: 0, display: "flex", flexDirection: "column" }}>
                                                            <Text component={"span"} selectable={false} variant={"body2"}>{query}</Text>
                                                        </p>
                                                    </MenuListItem>
                                                }

                                                {
                                                    results.map((r) => {
                                                        return <MenuListItem key={r} icon={<FiSearch size={14} style={{ flexShrink: 0 }} />} onClick={() => { setQuery(r); onUpdate(r) }}>
                                                            <p style={{ margin: 0, display: "flex", flexDirection: "column" }}>
                                                                <Text component={"span"} selectable={false} variant={"body2"}>{r}</Text>
                                                            </p>
                                                        </MenuListItem>
                                                    })
                                                }
                                            </div>
                                        </div>
                                }
                            </div>
                        } style={{ width: "100%", marginTop: "8px" }}>
                        <FormInput
                            bare
                            type="search"
                            value={query}
                            name={"searchQuery"}
                            placeholder='Search'
                            onChange={event => {
                                onChange({ searchQuery: event.target.value });
                                onUpdate(event.target.value??"");
                            }}
                            autoComplete={'off'}
                            rootStyle={{
                                width: 'calc(100% - 36px)', flexGrow: "unset"
                            }}
                        />
                    </MenuWrapper>
                </form>
            </div>
 
        </div>
    )
};

export default BrowseTaskersSearchBox;